@mixin txt-font-12($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-12; font-weight: $fw; line-height: $fs-16;
}

@mixin txt-font-14($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-14; font-weight: $fw; line-height: $fs-18;
}

@mixin txt-font-16($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-16; font-weight: $fw; line-height: $fs-20;
}

@mixin txt-font-18($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-18; font-weight: $fw; line-height: $fs-22;
}

@mixin txt-font-20($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-20; font-weight: $fw; line-height: $fs-24;
}

@mixin txt-font-24($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-24; font-weight: $fw; line-height: $fs-28;
}

@mixin txt-font-30($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-30; font-weight: $fw; line-height: $fs-34;

	@include media-breakpoint-down(lg) {
		font-size: $fs-28; line-height: $fs-32;
	}

	@include media-breakpoint-down(md) {
		font-size: $fs-26; line-height: $fs-30;
	}

	@include media-breakpoint-down(sm) {
		font-size: $fs-24; line-height: $fs-28;
	}
}

@mixin txt-font-36($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-36; font-weight: $fw; line-height: $fs-40;

	@include media-breakpoint-down(lg) {
		font-size: $fs-32; line-height: $fs-38;
	}

	@include media-breakpoint-down(md) {
		font-size: $fs-30; line-height: $fs-34;
	}

	@include media-breakpoint-down(sm) {
		font-size: $fs-28; line-height: $fs-32;
	}
}

@mixin txt-font-40($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-40; font-weight: $fw; line-height: $fs-44;

	@include media-breakpoint-down(lg) {
		font-size: $fs-36; line-height: $fs-40;
	}

	@include media-breakpoint-down(md) {
		font-size: $fs-34; line-height: $fs-38;
	}

	@include media-breakpoint-down(sm) {
		font-size: $fs-32; line-height: $fs-36;
	}
}

@mixin txt-font-40-sub($color, $fw) {
	color: $color; font-family: $fm-lato; font-size: $fs-40; font-weight: $fw; line-height: $fs-44;

	@include media-breakpoint-down(lg) {
		font-size: $fs-34; line-height: $fs-36;
	}

	@include media-breakpoint-down(md) {
		font-size: $fs-28; line-height: $fs-20;
	}

	@include media-breakpoint-down(sm) {
		font-size: $fs-22; line-height: $fs-24;
	}
}

@mixin txt-font-50($color, $fw, $lh) {
	color: $color; font-family: $fm-lato; font-size: $fs-50; font-weight: $fw; line-height: $lh;

	@include media-breakpoint-down(lg) {
		font-size: $fs-46; line-height: $fs-48;
	}

	@include media-breakpoint-down(md) {
		font-size: $fs-44; line-height: $fs-46;
	}

	@include media-breakpoint-down(sm) {
		font-size: $fs-40; line-height: $fs-42;
	}
}

@mixin underline-txt($color, $ht, $wh, $mt) {
	&::after {
		background: $color; content: ' '; display: block; height: $ht; margin: 0px 0px 2rem -3rem; width: $wh;

		@include media-breakpoint-down(xs) {
			height: 3px;
			width: 35%;
		}

	}
}

@mixin underline-txt-start($color, $ht, $wh, $mt) {
	&::after {
		background: $color; content: ' '; display: block; height: $ht; margin: 0px 0px 2rem -3rem; width: $wh;

		@include media-breakpoint-down(xs) {
			height: 3px;
			width: 35%;
		}

	}
}
