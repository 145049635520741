.banner-contact {
	@include py-52;

	.title {
		@include mb-40;
		color: $white;
		font-weight: $fw-extrabold;
		margin-top: 0.8rem;
		text-transform: uppercase;
	}
}

.banner-menu {
	@include py-52;
	.btn-main {
		border: 3px solid $white;
		border-radius: 2.25rem;
		font-size: 1.5rem;
	}

	.title {
		@include mb-24;
		color: $white;
		font-weight: $fw-extrabold;
		margin-top: 0.8rem;
		font-size: 40px;
	}
}

.carousel-item {
	width: 100%;
	height: 546px;
	background-position: top;
	background-size: 100%;
	&.slide-1 {
		background: url(#{$_image-path}/png/bkg-main.png) center top / cover no-repeat;
	}
	&.slide-2 {
		background: url(#{$_image-path}/png/bkg-main-2.png) center top / cover no-repeat;
	}
	.container {
		.btn-main {
			margin-top: 22rem;
			border: 3px solid $white;
			border-radius: 2.25rem;
			font-size: 1.5rem;
		}
	}
}

.gallery-car {
	.carousel-item {
		width: 100%;
		height: auto;
		.contenedor {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}
		.foto-1 {
			background: url(#{$_image-path}/png/gal-1.png) center top / cover no-repeat;
			width: 332px;
			height: 480px;
		}
		.foto-2 {
			background: url(#{$_image-path}/png/gal-2.png) center top / cover no-repeat;
			width: 332px;
			height: 480px;
		}
		.foto-3 {
			background: url(#{$_image-path}/png/gal-3.png) center top / cover no-repeat;
			width: 332px;
			height: 480px;
		}
	}
	.carousel-control-next{
		margin-right: -8rem;
	}
	.carousel-control-prev{
		margin-left: -8rem;
	}
	.carousel-control-prev-icon,
	.carousel-control-next-icon {
		width: 51px;
		height: 51px;
	}
	.carousel-control-prev-icon {
		background-image: url(#{$_image-path}/svg/left-arrow.svg);
	}
	.carousel-control-next-icon {
		background-image: url(#{$_image-path}/svg/right-arrow.svg);
	}
}

.carousel-indicators [data-bs-target] {
	width: 15px !important;
	height: 15px !important;
	background-color: transparent !important;
	border: 3px solid #fff !important;
	border-radius: 15px !important;
	border-top: initial;
	border-bottom: initial;
}
.carousel-indicators .active {
	background-color: #ff99b4 !important;
	border-color: #ff99b4 !important;
}
