/*
 * SCSS BASE
 * by SalsaLab
 */

// TAGS
html {
	font-size: 100%;
}

body {
	font-size: 16px;
	margin: 0;
	max-width: 100%;
	overflow-x: hidden;
	padding-top: 96px;
	position: relative;
}

img {
	max-height: 100%;
	max-width: 100%;

	&.img-object {
		object-fit: cover;
		object-position: center;
	}
}

// h1 {}
h2 { @include txt-font-50($title-main, $fw-bold, $fs-46) }
h3 { @include txt-font-40($title-main, $fw-bold) }
h4 { @include txt-font-36($title-main, $fw-bold) }
h5 { @include txt-font-30($title-main, $fw-bold) }
// h6 {}
p { @include txt-font-16($p-main, $fw-light) }
b { font-weight: $fw-bold; }
a { @include txt-font-14($black, $fw-medium) }

// SELECTOR CLASS

// Buttons
.btn {
	@include txt-font-24($white, $fw-semibold);
	background-color: transparent;
	border: 4px solid;
	border-radius: 0.3125em;
	cursor: pointer;
	overflow: hidden;
	padding: 0.5em 1.6em;
	position: relative;
	text-transform: uppercase;
	transition: 0.5s;
	z-index: 1;

	&::before,
	&::after {
		background-color: $ah-menu;
		border-radius: 50%;
		content: '';
		display: block;
		height: 50px;
		position: absolute;
		transform: translate(-50%, -50%);
		transition: 1s ease;
		width: 50px;
		z-index: -1;
	}

	&::before {
		left: -1em;
		top: -1em;
	}

	&::after {
		left: calc(100% + 1em);
		top: calc(100% + 1em);
	}

	&:hover::before,
	&:hover::after {
		height: 410px;
		width: 410px;
	}

	&:hover {
		color: $black;
	}

	&:active {
		filter: brightness(0.8);
	}

	// Custom colors
	&.btn-main {
		border-color: $white;
		color: $white;

		&::before,
		&::after { background: $white; }

		&:hover { color: $title-main; }
	}

	&.btn-alt {
		border-color: $underline;
		color: $underline;

		&::before,
		&::after { background: $underline; }

		&:hover { color: $title-main !important; }
	}
}


// Titles underline
// .title-page {	@include underline-txt($ah-menu, 4px, 160px, 9px); font-weight: $fw-300; }

// Fonts size
// .fs-14 { @include txt-font-14($p-main, $fw-300) }
// .fs-18 { @include txt-font-18($p-main, $fw-300) }

// Colors
// .cl-alt {}

// Contents
// .ctnt-link {}

// Container custom query
@media (min-width: 1280px) {
	.container {
		max-width: 1220px;
	}
}

@media (min-width: 1440px) {
	.container {
		max-width: 1380px;
	}
}
