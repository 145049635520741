.section-aboutus {
	@include py-100;

	.title {
		text-transform: uppercase;

		&.main {
			@include underline-txt-start($underline, 6px, 96px, 16px);

			@include media-breakpoint-down(xs) {
				@include underline-txt($underline, 6px, 96px, 8px);
				text-align: center;
			}
		}

		&.sec {
			@include underline-txt-start($underline, 6px, 96px, 8px);
			@include mt-74;

			@include media-breakpoint-down(xs) {
				@include underline-txt($underline, 6px, 96px, 8px);
				text-align: center;
			}
		}
	}

	.discover {

		.png {
			&.title {

				@include media-breakpoint-down(xs) {
					left: 50%;
					position: relative;
					right: unset;
					top: -1rem;
					transform: translateX(-50%);
				}
			}

			&.thisIs {
				height: 690px;
				width: 398px;
			}
		}

		.txt {
			max-width: 80%;

			@include media-breakpoint-down(xs) {
				max-width: 100%;
				text-align: center;
			}
		}
	}
}
.mt-6{
	margin-top: 6rem;
}

.feature {
	img{
		box-shadow: 0px 2px 10px 0px rgba(101, 101, 101, 0.5);
	-webkit-box-shadow: 0px 2px 10px 0px rgba(101, 101, 101, 0.5);
	-moz-box-shadow: 0px 2px 10px 0px rgba(101, 101, 101, 0.5);
	}
}