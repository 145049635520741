.bkg-img {
	background-color: $title-main;
	width: 100vw;

	&.top-section {
		//background: url(#{$_image-path}/png/bkg-main.png) center / cover no-repeat;
		height: 546px;

		@include media-breakpoint-down(xs) {
			background: url(#{$_image-path}/png/bkg-main-xs.png) center / cover no-repeat;
			height: 193px;
		}
	}

	&.banner-contact { background: url(#{$_image-path}/png/bkg-services.png) center / cover no-repeat; }
	&.banner-menu { background: url(#{$_image-path}/png/bkg-menu.png) center / cover no-repeat; }
	//&.section-form { background: url(#{$_image-path}/png/bkg-contact.png) center / cover no-repeat; }
	&.section-form { background: #EDB1BF center / cover no-repeat; }

}
.facebook,.instagram{
	width: 30px;
	height: 30px;
	font-size: 0;
	display: inline-flex;
}
.facebook{
	background: url(#{$_image-path}/svg/face.svg) center / contain no-repeat;
}
.instagram{
	background: url(#{$_image-path}/svg/insta.svg) center / contain no-repeat;
}
