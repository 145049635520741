.section-services {
	@include py-72;

	.title {
		@include underline-txt($underline, 5px, 96px, 8px);
		text-transform: uppercase;
	}

	.wrapper-service {
		@include mb-72;

		.ctnt-txt {
			margin-top: 53px;

			&.right {
				margin-left: 2rem;

				@include media-breakpoint-down(xs) {
					margin-left: 0;
				}
			}

			.subtitle {
				@include underline-txt-start($underline, 8px, 220px, 0);
				text-transform: uppercase;
				font-size: 40px;
    			font-weight: 800;

				@include media-breakpoint-down(xs) {
					@include underline-txt($underline, 8px, 220px, 0);
					text-align: center;
				}
			}

			.txt {
				max-width: 84%;

				@include media-breakpoint-down(xs) {
					max-width: 100%;
					text-align: center;
				}
			}
		}

		.ctnt-img {
			@include media-breakpoint-down(xs) {
				text-align: center;
			}
		}

		img {
			&.png {

				&.left {
					margin-left: 2rem;

					@include media-breakpoint-down(xs) {
						height: 12rem;
						margin-left: 0;
						width: 12rem;
					}
				}

				&.right {
					margin-left: 2rem;

					@include media-breakpoint-down(xs) {
						height: 12rem;
						// margin-left: 0;
						width: 12rem;
					}
				}
			}

			&.svg {
				opacity: 0.8;

				&.pos-right {
					bottom: -1.2rem;
					position: absolute;
					right: 1.8rem;

					@include media-breakpoint-down(xs) {
						height: 7rem;
						left: 0;
						margin-left: auto;
						margin-right: auto;
						right: 0;
						text-align: center;
					}
				}

				&.pos-left {
					bottom: -1.2rem;
					left: 0.1rem;
					position: absolute;

					@include media-breakpoint-down(xs) {
						height: 7rem;
						left: 0;
						margin-left: auto;
						margin-right: auto;
						right: 0;
						text-align: center;
					}
				}
			}
		}
	}
}
