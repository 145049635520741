.header {
	background-color: $white;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1030;

	.container-fluid {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		height: 96px;
		justify-content: space-between;
		padding: 0.5rem 1rem;
		position: relative;
		transition: all 0.3s ease-in-out;

		&.h-resize {
			height: 80px;
		}

		.site-logo {
			margin: 0.5em 1em;

			.site-logo-link {
				background: url(#{$_image-path}/svg/logo.svg) center / contain no-repeat;
				display: block;
				height: 3rem;
				overflow: hidden;
				text-indent: 101%;
				transition: all 0.5s ease;
				white-space: nowrap;
				width: 9.9rem;
			}
		}

		.dalba-menu {

			.menu-content {
				align-items: center;
				display: flex;

				@include media-breakpoint-down(md) {
					background-color: $white;
					display: block;
					left: 0;
					position: absolute;
					text-align: center;
					top: 5em;
					width: 100%;
				}

				.mnu {
					@include txt-font-14($black, $fw-medium);
					display: block;
					padding: 0.5rem 1rem;
					text-transform: uppercase;

					@include media-breakpoint-down(md) { display: none;	}

					&:hover {
						color: $ah-menu;
					}

					a{
						@include txt-font-14($black, $fw-medium);
						text-decoration: none;
						font-size: 18px;
					}
				}
			}

			.menu-mobile {
				color: $bkg-main;
				display: none;
				height: 2.5rem;
				margin-left: 1rem;
				padding-right: 10px;
				pointer-events: all;

				@include media-breakpoint-down(md) {
					display: flex;
					justify-content: flex-end;
					margin-right: 1rem;

					div {
						display: none;
						margin: 0;
						padding: 0;
						width: 100%;

						&:hover {
							background: lighten($black, 5%);
							transition-duration: 0.5s;
						}

						a {
							border-bottom: thin solid $white;
							color: $white;
							display: table;
							font-size: 20px;
							overflow: hidden;
							padding: 20px;
							text-decoration: none;
							width: 100%;
						}
					}

					&.burger {
						background: $bkg-main;
						border-radius: 10px;
						cursor: pointer;
						display: block;
						height: 40px;
						margin: 0;
						transform: translate(-10%, 0);
						transition: all 0.6s ease-in-out;
						width: 40px;

						i {
							background: $white;
							display: block;
							height: 2px;
							left: 50%;
							position: absolute;
							top: 50%;
							transform: translate(-50%, -50%);
							transition: all 0.2s ease-in-out;
							width: 20px;

							&::before,
							&::after {
								background: $white;
								content: '';
								height: 2px;
								left: 50%;
								position: absolute;
								top: 50%;
								transform: translate(-50%, -50%);
								transition: all 0.2s ease-in-out;
								width: 20px;
							}

							&::before {
								margin-top: -8px;
							}

							&::after {
								margin-top: 8px;
							}
						}

						&:hover {
							background: darken($bkg-main, 9%);

							i {
								&::before { margin-top: -8px;	}

								&::after { margin-top: 8px;	}
							}
						}

						&.active {
							background: $bkg-main;

							i {
								background: transparent;

								&::before,
								&::after {
									left: -5px;
									width: 30px;
								}

								&::before {
									top: 8px;
									transform: rotate(45deg);
								}

								&::after {
									top: -8px;
									transform: rotate(-45deg);
								}
							}

							&:hover {
								background: darken($red, 9%);

								i {
									&::before,
									&::after {
										left: -5px;
										margin: 0;
										top: 0;
										width: 30px;
									}
								}
							}
						}
					}
				}

				&::before {
					align-items: center;
					display: flex;
					height: 2.5rem;
					padding: 0 0 0 1.4rem;
				}
			}

		}
	}

}
