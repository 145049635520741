// Paddings X
// Paddings Y
@mixin py-100 {
	padding-bottom: $fs-100; padding-top: $fs-100;

	@include media-breakpoint-down(md) { padding-bottom: $fs-90; padding-top: $fs-90;	}
	@include media-breakpoint-down(sm) { padding-bottom: $fs-80; padding-top: $fs-80;	}
	@include media-breakpoint-down(xs) { padding-bottom: $fs-70; padding-top: $fs-70;	}
}

@mixin py-72 {
	padding-bottom: $fs-72; padding-top: $fs-72;

	@include media-breakpoint-down(md) { padding-bottom: $fs-60; padding-top: $fs-60;	}
	@include media-breakpoint-down(xs) { padding-bottom: $fs-48; padding-top: $fs-48;	}
}

@mixin py-52 {
	padding-bottom: $fs-52; padding-top: $fs-52;

	@include media-breakpoint-down(md) { padding-bottom: $fs-48; padding-top: $fs-48;	}
	@include media-breakpoint-down(xs) { padding-bottom: $fs-46; padding-top: $fs-46;	}
}

@mixin py-38 {
	padding-bottom: $fs-38; padding-top: $fs-38;

	@include media-breakpoint-down(md) { padding-bottom: $fs-36; padding-top: $fs-36;	}
	@include media-breakpoint-down(xs) { padding-bottom: $fs-34; padding-top: $fs-34;	}
}

@mixin py-30 {
	padding-bottom: $fs-30; padding-top: $fs-30;

	@include media-breakpoint-down(md) { padding-bottom: $fs-26; padding-top: $fs-26;	}
	@include media-breakpoint-down(xs) { padding-bottom: $fs-22; padding-top: $fs-22;	}
}

@mixin py-24 {
	padding-bottom: $fs-24; padding-top: $fs-24;
}

// Paddings Bottom
// Paddings Top
@mixin pt-100 {
	padding-top: $fs-100;

	@include media-breakpoint-down(md) { padding-top: $fs-90;	}
	@include media-breakpoint-down(sm) { padding-top: $fs-80;	}
	@include media-breakpoint-down(xs) { padding-top: $fs-70;	}
}
// Paddings Left
// Paddings Right
