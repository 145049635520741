/*! by SalsaLab */
// Helpers
@import 'bourbon';

// Framework
@import 'functions';
@import 'variables';

// Base Custom
@import 'theme-vars';
@import 'size-fonts';
@import 'margins';
@import 'paddings';

@import 'node_modules/bootstrap/scss/bootstrap';
// @import 'base/base';

// Layout
@import 'layout/base';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/bkg-sections';

// Home
@import 'home/about-us';
@import 'home/banners';
@import 'home/services';
@import 'home/catalog';
@import 'home/experience';
@import 'home/contact';
