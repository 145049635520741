.section-experience {
	@include py-72;

	.title {
		@include txt-font-50($title-main, $fw-medium, $fs-46);

		@include media-breakpoint-down(xs) {
			text-align: center;
		}

		b { font-weight: $fw-bold; }

	}

	img {
		&.exp {
			margin-left: 1rem;

			@include media-breakpoint-down(xs) {
				margin-left: 0;
			}
		}
	}
}
